.landing-page {
  padding: 2rem;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card-link {
  margin: 0 1rem 1rem 0;
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
}

.card-link:hover {
  transform: scale(1.05);
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.card-button {
  display: block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #6497d6;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.card-button:hover {
  background-color: #476c9b;
}
