  /* For WebKit browsers (Chrome, Safari, Edge) */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray background */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* Gray thumb */
    border-radius: 10px;
    border: 2px solid #f1f1f1; /* Adds space around thumb */
  }
  
  /* For Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f1f1f1;
  }

  .draggableCourse.drag-over {
    border-top: 2px solid blue;
    transition: border-top 0.2s ease;
  }

  .draggableCourse.is-dragging {
    opacity: 0;
  }

  /* Placeholder styling */
  .course-placeholder {
    background-color: #e0e0e0;
    margin: 5px 0;
    border-radius: 5px;
  }