.form-container {
    border: 0px solid #ccc;
    padding: 10px;
    border-radius: 8px;
  }
  
  .underline-input {
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    padding: 0;
    margin: 0;
    width: auto; /* Adjust the width of the underline */
    display: inline-block;
    background: none;
  }
  
  .smaller-underline-input {
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    padding: 0;
    margin: 0;
    width: 2.5em; /* Adjust the width of the underline */
    display: inline-block;
    background: none;
  }
  