.minor-dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .add-minor-dropdown .dropdown-toggle {
    background-color: #f0f0f0;
    border-color: #ccc;
    color: #333;
    font-weight: bold;
  }
  
  .add-minor-dropdown .dropdown-toggle:hover,
  .add-minor-dropdown .dropdown-toggle:focus {
    background-color: #e0e0e0;
    border-color: #999;
    color: #000;
  }
  
  .selected-minor-box {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .remove-minor-btn {
    background-color: transparent;
    border: none;
    color: #999;
    font-weight: bold;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .remove-minor-btn:hover {
    color: #333;
  }
  #add-minor-dropdown{
    color: black;
  }

  #add-major-dropdown{
    color: black;
    margin-left: 5px;

  }

  .hover #add-minor-dropdown{
    color: white;
  }