/* FinalizedCoursesNew.css */

/* Course Info and Columns */
.course-info {
  display: flex;
  justify-content: space-between; /* Distribute items evenly between columns */
  align-items: flex-start; /* Align items to the start of the flex container (top) */
  padding: 10px; /* Add padding for spacing */
}

.course-column {
  flex: 1; /* Each column takes equal width */
}

.course-column:first-child {
  margin-right: 20px; /* Add margin between the columns */
}

.expanded-details {
  text-align: left; /* Align expanded details to the right */
  margin-left: 20px; /* Add margin between the columns */
}

/* Course Card Styling */
.course-card {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Stretch items to take full width */
  justify-content: space-between;
  text-align: center;
  height: auto; /* Remove fixed height to allow content to expand */
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out; /* Transition for outline */
  padding: 10px; /* Add padding for spacing */
  margin-bottom: 10px; /* Add margin between cards */
  border: 1px solid #ccc; /* Keep the existing border color */
  background-color: #EFEFEF; /* Light blue background color for cards */
  border-radius: 10px; /* Optional: Add border radius for rounded corners */
}

/* Course Info Paragraphs */
.course-info p {
  margin: 0; /* Removes default margin for better centering */
}

.expanded-details p {
  margin-bottom: 10px; /* Add margin between expanded details */
}

/* Button Container Styling */
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
}

.button-container button {
  margin: 5px; /* Add margin to the buttons */
}

/* Additional Styling for Specific Classes */
.unmet-prereq {
  background-color: orange;
}

.kept-course {
  background-color: #CDCDCD;
  color: white;
}

/* Optional: Smooth Transition for Hover Outline */
.hover-outline {
  transition: border 0.2s ease-in-out;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .course-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-column:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .expanded-details {
    margin-left: 0;
  }
}