.credit-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: #b3d7ed;
    border-radius: 10px;
  }
  
  .credit-card-green {
    background-color: #a3e8b3;
  }
  
  .credit-card-blue {
    background-color: #b3d7ed;
  }
  
  .credit-card p {
    margin: 0;
  }