.class-cart {
  flex: 0 0 27.5%;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
}

.class-cart-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #999;
  background-color: whitesmoke;
}

.btn-primary,
.btn-danger {
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}
.remove-button-container {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
}

/* Add this style to show the remove button on hover */
.class-cart-item:hover .remove-button-container {
  height: auto;
}
