/* 
.card-container {
    
    border-radius: 1rem;
    box-shadow: 0px 0px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    background-color: white;
    height: fit-content;
  }
  
  .card-image {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .card-title {
    margin: 0.5rem 5%;
  }
  
  .card-description {
    margin: 0.5rem 5%;
  }
  
  .card-btn {
    text-align: center;
    width: 90%;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    text-decoration: none;
    margin: 0.5rem 0.75rem;
  }
   */



.card-container {
    
  border-radius: 1rem;
  box-shadow: 0px 0px 8px #999;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  background-color: whitesmoke;
  height:fit-content;
  overflow:auto;
}

.card-image {
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.card-title {
  margin: 0.5rem 5%;
  text-align: center;
  padding-bottom: 13px;
  color: inherit;
 text-decoration: none;
}

.card-description {
  margin: 0.5rem 5%;
}

/* .card-btn {
  text-align: center;
  width: 90%;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background-color: #2563eb;
  color: white;
  text-decoration: none;
  margin: 0.5rem 0.75rem;
} */

.underline-input {
  border: none;
  border-bottom: 1px solid #000; /* You can adjust the color and style as needed */
  outline: none;
  padding: 0;
  margin: 0;
  width: auto;
  display: inline-block;
  background: none;
}

.smaller-underline-input {
  border: none;
  border-bottom: 1px solid #000; 
  outline: none;
  padding: 0;
  margin: 0;
  width: 1.5em; /* Adjust the width of the underline */
  display: inline-block;
  background: none;
}
