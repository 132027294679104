/* General styling for the semester columns */
.column {
  flex: 1;
  margin: 0 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

.column-title {
  background-color: black;
  padding: 10px;
  text-align: center;
  width: 100%;
  color: white;
  border-radius: 10px 10px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cards-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto; /* Allow cards to grow and shrink equally */
  width: 600px; /* Make card take full width */
  background-color: white;
  margin: 5px; /* Reduce vertical margin */
  padding: -2px; /* Adjust padding */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.02);
}

.course-info {
  display: flex;
  justify-content: space-between;
  font-size: 12px; /* Reduce font size */
  margin-bottom: 2px; /* Reduce bottom margin */
}

select {
  margin: 0 5px;
  width: calc(33% - 10px);
}

.unmet-prereq {
  background-color: orange;
}

.kept-course {
  background-color: #d4edda;
}

.course-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px; /* Reduce padding */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.course-card.selected-course {
  border: 1px solid rgb(0, 169, 192);
}

/* New styling for the header and dropdown */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
}

.header h1 {
  margin: 0;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-container label {
  margin-right: 10px;
  font-weight: bold;
}

#minors-dropdown {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Ensure course number stays on one line */
.course-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust as necessary */
  text-align: left;
}

.course-name-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  text-align: center;
  max-width: 150px; /* Adjust as necessary */
}

.course-name-text.expanded {
  white-space: normal; /* Allow the text to wrap when expanded */
  overflow: visible;
  max-width: none; /* Allow the full width for expanded text */
}

.course-credits {
  text-align: right;
  min-width: 30px; /* Adjust as necessary */
}

.card .course-number, .card .course-credits {
  font-size: 14px; /* Reduce font size */
}

.card .course-name-text {
  font-size: 12px; /* Reduce font size */
}

.card .flex.items-center.justify-between.p-2 {
  line-height: 1.5; /* Maintain consistent line height */
}

/* Styling for the dropdowns and button */
.dropdown-container {
  margin: 10px 0; /* Add vertical margin for spacing */
}

select {
  border: 1px solid #ccc; /* Add border */
  width: auto; /* Allow width to adjust */
}

/* Smooth expanding effect */
.expandable-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.expandable-content.expanded {
  max-height: 500px; /* Adjust this value based on your content */
  opacity: 1;
}

.card.expanded .course-name-text {
  white-space: normal;
  overflow: visible;
  max-width: none;
}
