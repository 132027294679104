.collapsibleClassSearchContainer {
    border-style: none;
    border: 1px solid #ccc;
    padding: 5px;
    margin-bottom: 5px;
  }
  
  .classInfoContainer {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
  }
  
  .middleColumn {
    flex: 1;
    text-align: left;
    margin: 0 10px;
  }
  
  .rightColumn {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .btn {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .arrowIcon {
    font-size: 14px;
  }
  
  .collapsedIcon {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
  
  .expandedIcon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
  
  .additionalInfoContainer {
    padding-left: 0px;
  }
  
  .classInfoText {
    margin: 0;
  }
  
  .evaluationForm {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .evaluationForm p {
    margin: 0 0 10px;
  }
  
  .evaluationForm input {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    max-width: 80%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  