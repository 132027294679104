.dashboard-container {
    display: flex;
    height: 100vh; /* Full height to avoid unnecessary scroll */
}

.chat-container {
    flex: 1;
    background-color: white;
    border: 1px solid #e5e7eb; /* Tailwind's gray-200 */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow */
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden; /* Change this to 'hidden' to remove the scroll bar */
}

.conversation {
    margin-bottom: 20px;
}